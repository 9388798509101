<template>
  <div ref="move" class="box">
        <div class="iconfont icon-guanbi close" @click="close"></div>
        <div class="title" @mouseenter="mouseDown" @mouseleave="dragmove">飞行参数模拟</div>
        <div class="select">
            <div class="left">飞行视角：</div>
            <div class="right">
                <el-radio v-model="radio" label="1">跟随视角</el-radio>
                <el-radio v-model="radio" label="2">俯视视角</el-radio>
            </div>
        </div>
        <div class="suggest">设置飞行速度（建议1-25m/s）</div>
        <div class="speed-select">
            <el-slider class="scroll" v-model="value" :min="1" :max="25"></el-slider>
            <span>飞行速度 {{value}}m/s</span>
        </div>
        <div class="bottom">
            <el-button type="primary" @click="simulationStatusHandle">{{simulationStatus?'暂停模拟':'继续模拟'}}</el-button>
            <el-button type="primary" @click="restart">重新模拟</el-button>
        </div>
  </div>
</template>

<script>
import interact from 'interactjs'
import API from "@/api";
let x = 0  //  x轴的移动大小
let y = 0  //  y轴的移动大小
export default {
    props:{
        popupStates: {
            type: Function ,
            default: ()=>{},
        },
        routeSimulationData: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            radio: '1',
            value: 5,
            simulationStatus: true,
        }
    },
    methods: {
        moveElement(){  // 元素移动函数
            interact(this.$refs.move)
                .on('dragmove', function (event) {
                    x += event.dx
                    y += event.dy
                    event.target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'
                })
        },
        mouseDown() { //鼠标进入头部开启拖动事件
            interact(this.$refs.move)
                .draggable({
                    listeners: {
                        move(event) {
                            const target = event.target
                            const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
                            const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

                            target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

                            target.setAttribute('data-x', x)
                            target.setAttribute('data-y', y)
                        },
                    },
                    modifiers: [
                        interact.modifiers.snap({
                            targets: [
                            interact.snappers.grid({ x: 2, y: 2 }) // 设置每次移动的像素
                            ],
                            range: Infinity,
                            relativePoints: [ { x: 0, y: 0 } ]
                        }),
                        interact.modifiers.restrict({  // 设置只能在父元素上移动
                            restriction: this.$refs.move.parentNode,
                            elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
                            endOnly: true
                        })
                    ],
                    inertia: true
                })
                
        },
        dragmove() { //鼠标离开头部元素取消拖动事件
            interact(this.$refs.move).draggable(false)
        },
        close() { // 关闭弹框
            this.popupStates(false)
        },
        simulationStatusHandle() { // 暂停飞行或继续飞行
            this.simulationStatus = !this.simulationStatus
            if(this.simulationStatus){
                this.$bus.$emit('flySpeed',this.value)
            } else {
                this.$bus.$emit('flySpeed','stop')
            }
        },
        restart() { // 重新开始模拟
            this.value = 5
            this.simulationStatus = true
            this.$bus.$emit('flySpeed','restart')
        }


    },
    watch: {
        value(val) {
            if(this.simulationStatus){
                this.$bus.$emit('flySpeed',val)
            }
        },
        radio(val) {
            this.$bus.$emit('view',val)
        }
    },
    mounted() {
        this.moveElement()
        if (this.routeSimulationData.speed && this.routeSimulationData.speed < 26) {
            this.value = this.routeSimulationData.speed
        }
    }
    
}
</script>

<style lang="scss" scoped>
::v-deep{
    .el-radio__input.is-checked+.el-radio__label {
        color: #44b1fa;
    }
    .el-radio__input.is-checked .el-radio__inner {
        border-color: #44b1fa;
        background: #44b1fa;
    }
    .el-radio{
        color: #ddd;
    }
}
.box{
    z-index: 100;
    position: absolute;
    width: 500px;
    top: 100px;
    right: 40px;
    background: rgba(8, 37, 79, 0.8);
    border-radius: 2px;
    border: 2px solid #44b1fa;
    color: #44b1fa;
    padding: 20px;
    .title{
        font-size: 18px;
        margin-bottom: 16px;
        text-align: center;
        font-weight: bolder;
        border-bottom: 2px solid #44b1fa;
        padding-bottom: 10px;
    }
    .close{
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 18px;
        cursor: pointer;
    }
    .select{
        display: flex;
        justify-content: left;
        align-items: center;
        .left{
            font-size: 16px;
            margin-right: 20px;
            font-weight: bold;
        }
    }
    .suggest {
        font-size: 18px;
        margin-top: 20px;
    }
    .speed-select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .scroll {
            width: 350px;
        }
    }
    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 70px 0 70px;
    }
}
</style>