<template>
    <div ref="move" class="box">
        <div class="iconfont icon-guanbi close" @click="close"></div>
        <div class="title" @mouseenter="mouseDown" @mouseleave="dragmove">飞行模拟</div>
        <!-- 搜索 -->
        <el-form
            :model="search_form"
            style="margin-left: 24px;margin-bottom: 20px;"
            class="linear-form"
            inline
        >
            <el-form-item label="航线名称:">
            <el-input
                v-model="search_form.flightName"
                placeholder="请输入航线名称"
                clearable
            ></el-input>
            </el-form-item>
            <el-form-item label="">
            <div @click="search" class="linear-btn">
                搜索
            </div>
            </el-form-item>
        </el-form>
        <!-- 列表展示 -->
        
        <Table
            class="task-table"
            :columns="columns"
            :dataSource="dataSource"
            :pagination="pagination"
            @size-change="
            (size) => {
                pagination.pageSize = size;
                async_get_list();
            }
            "
            @page-change="
            (page) => {
                pagination.page = page;
                async_get_list();
            }
            "
        >
            <template #time="{ row }">
            <div class="action">
                {{ row.time }}
            </div>
            </template>
            
            <template #action="{ row }">
            <div class="action">
                <span title="航线模拟" class="iconfont icon-feihangguiji" @click="routeSimulation(row)"></span>
                <span title="更改" class="iconfont icon-genggai" @click="edit(row)"></span>
                <span title="删除" class="iconfont icon-shanchu" @click="del(row)"></span>
            </div>
            </template>
        </Table>
    </div>
</template>

<script>
import interact from 'interactjs'
import Table from "@/components/common/table";
import API from "@/api";
let x = 0  //  x轴的移动大小
let y = 0  //  y轴的移动大小
export default {
    components: { Table },
    props:{
        popupStates: {
            type: Function ,
            default: ()=>{},
        },
        editFlyLine: {
            type: Function ,
            default: ()=>{},
        }
    },
    data() {
        return {
            search_form: {
                flightName: ''
            },
            columns: [
                { prop: "flightName", label: "航线名称" },
                { prop: "pointCount", label: "航点数量（个）" },
                { prop: "distance", label: "航线长度（km）" },
                { prop: "speed", label: "默认速度（m/s）" },
                { prop: "time", slot: true, label: "预计所需时间（min）" },
                { prop: "action", slot: true, label: "操作" },
            ],
            dataSource: [],
            pagination: {
                page: 1,
                pageSize: 10,
                total: 0,
            },
        }
    },
    methods: {
        moveElement(){  // 元素移动函数
            interact(this.$refs.move)
                .on('dragmove', function (event) {
                    x += event.dx
                    y += event.dy
                    event.target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'
                })
        },
        mouseDown() { //鼠标进入头部开启拖动事件
            interact(this.$refs.move)
                .draggable({
                    listeners: {
                        move(event) {
                            const target = event.target
                            const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
                            const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

                            target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

                            target.setAttribute('data-x', x)
                            target.setAttribute('data-y', y)
                        },
                    },
                    modifiers: [
                        interact.modifiers.snap({
                            targets: [
                            interact.snappers.grid({ x: 2, y: 2 }) // 设置每次移动的像素
                            ],
                            range: Infinity,
                            relativePoints: [ { x: 0, y: 0 } ]
                        }),
                        interact.modifiers.restrict({  // 设置只能在父元素上移动
                            restriction: this.$refs.move.parentNode,
                            elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
                            endOnly: true
                        })
                    ],
                    inertia: true
                })
                
        },
        dragmove() { //鼠标离开头部元素取消拖动事件
            interact(this.$refs.move).draggable(false)
        },
        close() { // 关闭弹框
            this.popupStates(false)
        },
        search() { //搜索按钮
            this.pagination.page = 1
            this.async_get_list()
        },
        async async_get_list() { //发起请求
            const { page, pageSize } = this.pagination;
            let res = await API.AIRWAY.flightLineListAdc({
                ...this.search_form,
                pageNo: page,
                pageSize: pageSize,
            });
            res = res.data
            this.dataSource = res.data || [];
            this.pagination.pageSize = res.pageSize;
            this.pagination.total = res.totalCount;
        },
        routeSimulation(item) { // 点击航线模拟
            this.popupStates(false,item)
        },
        async del(data) { // 点击删除
            const res = await API.AIRWAY.deleteFlightLineAdc({id: data.id})
            if (res.status === 1) {
                this.$message.success('删除成功')
                if(this.dataSource.length === 1) {
                    if(this.pagination.page > 1) {
                        this.pagination.page --
                    }
                }
                this.async_get_list();
            } else {
                this.$message.error(res.message)
            }
        },
        edit(item) { // 编辑航线
            this.editFlyLine({...item})
            this.close()
        }

    },
    mounted() {
        this.moveElement()
        this.async_get_list()
    }
    
}
</script>

<style lang="scss" scoped>
::v-deep{
    .el-form-item{
            .el-input__inner{
            width: 300px !important;
        }
    }
    .table-pagination{
        height: 50px !important;
        padding: 10px 0;
    }
    
}
.box{
    z-index: 10;
    position: absolute;
    width: 1400px;
    top: 40px;
    left: 50%;
    margin-left: -700px;
    background: rgba(8, 37, 79, 0.8);
    border-radius: 2px;
    border: 1px solid #2b9fed;
    padding: 20px;
    overflow: auto;
    .close{
        position: absolute;
        top: 18px;
        right: 16px;
        color: #44b1fa;
        font-size: 24px;
        cursor: pointer;
    }
    .title{
        font-size: 24px;
        color: #44b1fa;
        padding-bottom: 10px;
        text-align: center;
        margin-bottom: 20px;
        font-weight: bold;
        border-bottom: 2px solid #44b1fa;
    }
    .task-table{
        width: 100%;
        height: 100%;
        max-height: 530px;
        .iconfont{
            font-size: 20px;
            cursor: pointer;
        }
    }
    
}
</style>