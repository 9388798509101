<template>
  <div class="cpt-cesium-layer">
        <!-- 下载模板 -->
        <div class="public download-mould" @click="downloadMould">
            <el-tooltip  content="模板下载" placement="right">
                <div class="iconfont icon-xiazaimoban" ></div>
            </el-tooltip>
        </div>
        <!-- 导入要素 -->
        <div class="public import-yaosu" @click="importYaosuPopup = true">
            <el-tooltip  content="导入要素" placement="right">
                <div class="iconfont icon-daoruyaosu" ></div>
            </el-tooltip>
        </div>
        <YaosuPopup v-if="importYaosuPopup" :updateElenmentF="updateElenmentF"  :popupStates="popupStates"/>
        <!-- 飞行模拟 -->
        <div class="public fly-simulation" @click="flySimulationPopup = true">
            <el-tooltip  content="飞行模拟" placement="right">
                <div class="iconfont" >
                  <img src="./img/fxmn.png" style="width:24px;height:24px;margin-top:6px" alt="">
                </div>
            </el-tooltip>
        </div>
        <FlyPupop v-if="flySimulationPopup" :editFlyLine="editFlyLine" :popupStates="flyPopupStates"/>
        <RouteSimulation v-if="routeSimulationPopup" :routeSimulationData="routeSimulationData" :popupStates="routeSimulationStates"/>
        <!-- 多期对比 -->
        <div class="public contrast" @click="contrastStates = true">
            <el-tooltip  content="多期对比" placement="right">
                <div class="iconfont " >
                  <img src="./img/dqdb.png" style="width:24px;height:24px;margin-top:6px" alt="">
                </div>
            </el-tooltip>
        </div>
        <ContrastPopup v-if="contrastStates" :contrastPopupControl="contrastPopupControl"/>
        <!-- 跳转回信息模式按钮 -->
        <div class="public to-info-mode" @click="toInfoMode">
            <el-tooltip  content="信息模式" placement="right">
                <div class="iconfont icon-xinximoshi" ></div>
            </el-tooltip>
        </div>
        <!-- 地球容器 -->
        <Cesium :updateElement="updateElement" :routeSimulationData="routeSimulationData" :editFlyLineData="editFlyLineData" :closeFlyLine="closeFlyLine"/>
        <!-- <CesiumFly /> -->
        <FiexdRight/>

        <!-- 地图切换组件（2D/街道） -->
        <transition
          name="animate__animated dh"
          key="1"
          enter-active-class="animate__fadeInUp"
          leave-active-class="animate__fadeOutDown"
          >
            <Bottomright />
        </transition>
        
  </div>
</template>

<script>
import FiexdRight from "@/components/fiexdright";
import API from "@/api";
import YaosuPopup from './components/import-yaosu-pupup.vue'  //导入要素图标
import FlyPupop from './components/fly-simulation-popup.vue' // 飞行模拟弹框
import RouteSimulation from './components/route-simulation-popup.vue' // 飞行参数模拟弹框
import ContrastPopup from './components/contrast-popup.vue' // 多期对比弹框
import Cesium from "./components/cesium.vue"; // 地球
import Bottomright from '@/components/Bottomright';//地图切换（2D/街道）组件
export default {
    components: { YaosuPopup, FlyPupop, RouteSimulation, Cesium, ContrastPopup , FiexdRight, Bottomright},
    data(){
        return{
            flySimulationPopup: false, // 控制飞行模拟弹框是否展示
            routeSimulationPopup: false, // 控制飞行参数模拟弹框是否展示
            routeSimulationData: {}, // 传给飞行参数模拟弹框的数据
            importYaosuPopup: false, // 控制导入要素弹框的显示与隐藏
            updateElement: 0, // 通知重新加载全要素
            closeFlyLine: 0, // 通知关闭航线规划弹框
            editFlyLineData: {}, // 通知要修改航线了，保存着航线的数据
            contrastStates: false, // 多期对比弹框
        }
    },
    methods: {
        contrastPopupControl() { // 控制多期对比弹框
          this.contrastStates = false
        },
        popupStates(boolean) { // 控制导入要素弹框状态
          this.importYaosuPopup = boolean
        },
        flyPopupStates(boolean,item) { // 控制飞行模拟弹框状态
          this.flySimulationPopup = boolean
          if(item) {
            this.routeSimulationStates(true,item)
          }
        },
        routeSimulationStates(boolean,item) { // 控制飞行参数模拟弹框
          this.routeSimulationData = {...item}
          this.routeSimulationPopup = boolean
        },
        toInfoMode() { // 跳转到信息模式
            this.$router.push('/platform/poi')
        },
        async downloadMould(){ // 模板下载
            try {
                const blob = await API.POI.downloadMould()
                if (blob) {
                    let binaryData = [];
                    binaryData.push(blob);
                    const a = document.createElement("a")
                    a.href = URL.createObjectURL(new Blob(binaryData))
                    a.download = "元素导入模板.xlsx"
                    a.click()
                }
            } catch (e) {
                console.error(e);
            }
        },
        updateElenmentF() { // 改变updateElement值，触发改变更新全要素图标
          this.updateElement += 1
        },
        editFlyLine(item) {
          this.editFlyLineData = item
        }
    },
    watch: {
      routeSimulationPopup(val) {  // 监视飞行参数模拟弹框关闭了去做某些事情
        if(!val){
          this.$bus.$emit('close')
        }
      },
      flySimulationPopup(val) {  // 监视飞行模拟弹框是否出现，出现的话关掉飞行参数模拟弹框
        if(val){
          this.routeSimulationPopup = false
          this.closeFlyLine += 1
        }
      }
    },
    mounted() {
    },
};
</script>

<style lang="scss" scoped>
::v-deep{
    .el-input__inner{
        background: rgba(8, 37, 79, 0.7);
        border: 1px solid #3989ff;
        border-right: 0;
        border-radius: 2px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        color: #c4d6ff;
    }
    .el-input-group__append{
        background: rgba(8, 37, 79, 0.7);
        border: 1px solid #3989ff;
        border-left: 0;
        border-radius: 2px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        color: #c4d6ff;
    }
    .el-button--primary:hover {
        background: #3989ff;
        border-color: #3989ff;
        color: #c4d6ff;
    }
    .el-button--primary {
        background: #4e93fb;
        border-color: #4e93fb;
        color: #c4d6ff;
    }
}
.public{
      z-index: 1000;
      position: absolute;
      left: 41px;
      width: 44px;
      height: 44px;
      background: rgba(6, 25, 56, 0.7);
      border: 1px solid #007aff;
      display: flex;
      justify-content: center;
      align-items: center;
      .iconfont{
        font-size: 24px !important;
        color: #1ca3ff !important;
        margin-top: -2px;
        cursor: pointer;
      }
}
.cpt-cesium-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  .to-info-mode{
      top: 440px;
  }
  .contrast{
      top: 384px;
  }
  .download-mould{
      top: 160px;
  }
  .import-yaosu{
      top: 216px;
  }
  .fly-simulation{
      top: 272px;
  }
}
</style>
