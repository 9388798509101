<template>
  <div class="popup">
      <div class="container">
          <div class="iconfont icon-guanbi close" @click="close"></div>
          <div class="img">
              <el-upload
                class="avatar-uploader"
                action=""
                accept=".png,.webp,.img,.jpg,.jpeg"
                :auto-upload="false"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :on-change="beforeAvatarUpload">
                    <img v-if="imageUrl1" :src="imageUrl1" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
          </div>
          <div class="img">
              <el-upload
                class="avatar-uploader"
                action=""
                accept=".png,.webp,.img,.jpg,.jpeg"
                :auto-upload="false"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :on-change="beforeAvatarUpload2">
                    <img v-if="imageUrl2" :src="imageUrl2" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: { 
        contrastPopupControl: {
            type: Function,
            default: () => () => {},
        },
    
    },
    data() {
        return {
            imageUrl1:'',
            imageUrl2:'',
        }
    },
    methods: {
        close() {
            this.contrastPopupControl()
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            // console.log(file)
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            // const isLt2M = file.raw.size / 1024 / 1024 < 0.5  //限制上传文件的大小
            if (!isPNG && !isJPG) {
                this.$message.error('上传图片只能是 JPG/PNG 格式!')
                return false
            } else {
                this.imageUrl1 = URL.createObjectURL(file.raw);//赋值图片的url，用于图片回显功能
            }
        },
        beforeAvatarUpload2(file) {
            // console.log(file)
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            // const isLt2M = file.raw.size / 1024 / 1024 < 0.5  //限制上传文件的大小
            if (!isPNG && !isJPG) {
                this.$message.error('上传图片只能是 JPG/PNG 格式!')
                return false
            } else {
                this.imageUrl2 = URL.createObjectURL(file.raw);//赋值图片的url，用于图片回显功能
            }
        }
    }

}
</script>

<style lang="scss" scoped>
::v-deep{
    .el-upload{
        width: 100%;
    }
}
.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    background: rgba(8, 37, 79, 0.8);
    
    .container{
        background: rgba(8, 37, 79, 1);
        width: 1000px;
        height: 600px;
        border: 2px solid #0067ab;
        position: absolute;
        top: 100px;
        left: 50%;
        margin-left: -500px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        .close{
            position: absolute;
            top: -20px;
            right: -80px;
            width: 40px;
            height: 40px;
            color: #0067ab;
            cursor: pointer;
            
        }
        .iconfont{
            font-size: 40px;
        }
        .img{
            width: 48%;
            height: 100%;
            background-color: #fff;
            border: 2px solid #0067ab;
            .avatar-uploader{
                border-radius: 6px;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                .avatar-uploader-icon {
                    font-size: 28px;
                    cursor: pointer;
                    color: #8c939d;
                    width: 100%;
                    height: 100%;
                    line-height: 100%;
                    text-align: center;
                }
                .el-upload{
                    width: 100%;
                    .avatar {
                    width: 100% !important;
                    height: 100% !important;
                    display: block;
                }
                }
                
            }
            
        }
        
    }
}
</style>