<template>
  <div ref="move" class="box">
        <div class="iconfont icon-guanbi close" @click="close"></div>
        <div class="title">
            要素导入
        </div>
        <el-upload
            action=""
            accept=".xls,.xlsx"
            :auto-upload='false'
            class="upload-demo"
            :on-change="onChange"
            :limit="2"
            :file-list="fileList">
            <div slot="tip" class="el-upload__tip">只能上传.xls .xlsx文件</div>
            <el-button type="primary" >点击上传</el-button>
        </el-upload>
        <div class="sub">
            <el-button  type="primary" @click="sub">确定</el-button>
        </div>
  </div>
</template>

<script>
import interact from 'interactjs'
import API from "@/api";
let x = 0  //  x轴的移动大小
let y = 0  //  y轴的移动大小
export default {
    props:{
        popupStates: {
            type: Function ,
            default: ()=>{},
        },
        updateElenmentF: {
            type: Function ,
            default: ()=>{},
        },
    },
    data() {
        return {
            fileList: [],
        }
    },
    methods: {
        moveElement(){  // 元素移动函数
            interact(this.$refs.move)
                .draggable({
                    modifiers: [
                    interact.modifiers.snap({
                        targets: [
                        interact.snappers.grid({ x: 2, y: 2 }) // 设置每次移动的像素
                        ],
                        range: Infinity,
                        relativePoints: [ { x: 0, y: 0 } ]
                    }),
                    interact.modifiers.restrict({  // 设置只能在父元素上移动
                        restriction: this.$refs.move.parentNode,
                        elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
                        endOnly: true
                    })
                    ],
                    inertia: true
                })
                .on('dragmove', function (event) {
                    x += event.dx
                    y += event.dy
                    event.target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'
                })
        },
        onChange(file, fileList1) {
            if (fileList1&&fileList1.length>=2) {
                fileList1.shift();
            }
            this.fileList = fileList1;
        },
        close() { // 关闭弹框
            this.popupStates(false)
        },
        async sub() { // 提交文件
            if(this.fileList.length>0) {
                let res = null
                let formData = new FormData()
                formData.append("file", this.fileList[0].raw)
                try {
                    res = await API.POI.uploadYaosu(formData);
                    if (res.code === 200) {
                        this.$message.success('数据添加成功!')
                        this.updateElenmentF()
                        this.popupStates(false)
                    } else {
                        this.$message.error('数据添加失败,请按照模板上传文件!');
                    }
                } catch (error) {
                    console.log(error);
                }
                
            } else {
                this.$message.warning('请先选择文件!');
            }
        }

    },
    mounted() {
        this.moveElement()
    }
    
}
</script>

<style lang="scss" scoped>
::v-deep{
    .el-upload-list__item-name{
        color: #0067ab;
        .el-icon-document{
            ::before{
                color: #0067ab;
            }
        }
    }
    .el-upload__tip{
        color: #bbb;
    }
    .el-upload-list__item-name [class^=el-icon] {
    height: 100%;
    margin-right: 7px;
    // color: #0067ab;
    color: #bbb;
    line-height: inherit;
}
    .el-upload-list {
        // background: #000;
    }
    .el-upload-list__item:hover {
        background: #f5f7fa;
        .el-upload-list__item-name {
        color: #0067ab;
    }
        
        .el-upload-list__item-name [class^=el-icon] {
            height: 100%;
            margin-right: 7px;
            color: #0067ab;
            // color: #bbb;
            line-height: inherit;
        }
        .el-icon-close {
        color: #0067ab;
        margin-top: 8px;
    }
    }
    .el-upload-list__item-name {
        color: #bbb;
    }
    .el-icon-close {
        color: #bbb;
    }
}
.box{
    z-index: 100;
    position: absolute;
    width: 300px;
    top: 200px;
    left: 50%;
    margin-left: -150px;
    background: rgba(8, 37, 79, 0.8);
    border-radius: 2px;
    border: 1px solid #0067ab;
    padding: 20px;
    .sub{
        text-align: right;
        margin-top: 20px;
    }
    .title{
        font-size: 18px;
        color: #2b9fed;
        margin-bottom: 16px;
        text-align: center;
        font-weight: bolder;
    }
    .close{
        position: absolute;
        top: 20px;
        right: 20px;
        color: #2b9fed;
        font-size: 18px;
        cursor: pointer;
    }
}
</style>